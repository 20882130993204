<template>
  <div class="merchaprrejodr">
    <div class="panel-body row">
      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-5" style="">
        <input
          type="text"
          v-model="searchKey"
          class="form-control"
          id="input-store"
          placeholder="Search By Order ID"
          value=""
          name="voucher"
        />
      </div>
      <div class="col-lg-9 col-md-9 col-sm-9 col-xs-7 text-right">
        Order Status
        <select
          v-model="searchKeyStatus"
          style="display: inline-block; width: 200px; margin-right: 18px; margin-left: 5px"
          class="form-control select-arrow"
        >
          <option
            v-for="(option, index) in paymentFilteroptions"
            :key="index"
            :value="option.value"
            >{{ option.text }}</option
          >
        </select>
        <button
          @click="filterOrderPayType()"
          style="vertical-align: inherit; margin-right: 12px;"
          class="btn merchprof-sbtn"
        >
          Filter
        </button>
      </div>
    </div>
    <div>
      <table id="example" class="table table-striped table-border-out">
        <thead>
          <tr>
            <th class="head-table">Order Id</th>
            <th class="head-table">Status</th>
            <th class="head-table">Amount</th>
            <!-- <th class="head-table">Mobile</th> -->
            <!-- <th class="head-table">Email</th> -->
            <th class="head-table">Payment Method</th>
            <th class="head-table">Action</th>
          </tr>
        </thead>
        <tbody>
          <div class="col-md-12" id="preview-area" v-if="blockLoading">
            <div class="row text-center">
              <div class="sk-chase">
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
              </div>
            </div>
          </div>
          <tr v-for="(item, index) in tableItems" :key="index">
            <td
              class="text-right col-md-4"
              style="vertical-align: middle; height: 80px;padding: 10px;"
            >
              <!-- {{ item.order_env_number }} -->
              <div class="row">
                <div class="col-md-6 col-sm-6" v-if="item.order_type != 2">
                  <span style="font-size: 15px;"
                    ><b>{{ item.order_env_number }}</b></span
                  ><br />
                  <!-- <span v-if="item.order_id.billing_name !== 'undefined undefined'">{{ item.order_id.billing_name }}</span> -->
                  {{
                    item.user_id != undefined
                      ? item.user_id.first_name != undefined
                        ? item.user_id.first_name
                        : " "
                      : " "
                  }}
                  {{
                    item.user_id != undefined
                      ? item.user_id.last_name != undefined
                        ? item.user_id.last_name
                        : " "
                      : " "
                  }}
                </div>
                <div class="col-md-6 col-sm-6" v-else>
                  <span style="font-size: 15px;"
                    ><b>{{ item.order_env_number }}</b></span
                  ><br />
                  {{
                    item.shipping_name != undefined ? item.shipping_name : " "
                  }}
                </div>
                <div class="col-md-6 col-sm-6" v-if="item.order_type != 2">
                  Date : {{ item.date }} <br />
                  {{
                    item.address_id != undefined
                      ? item.address_id.city != undefined
                        ? item.address_id.city.city_name
                        : " "
                      : " "
                  }}
                </div>
                <div class="col-md-6 col-sm-6" v-else>
                  Date : {{ item.date }} <br />
                  {{
                    item.city != undefined
                      ? item.city.city_name != undefined
                        ? item.city.city_name
                        : " "
                      : " "
                  }}
                </div>
              </div>
            </td>
            <td
              class="text-right"
              style="vertical-align: middle; text-align: left;"
            >
              {{
                item.itemCount > 1
                  ? item.itemCount + " Items"
                  : item.itemCount + " Item"
              }}
              <div>
                <span style="font-weight: bold;">Pending</span>
              </div>
            </td>
            <!-- <td class="text-right"  style="vertical-align: middle; text-align: left;">{{ item.billing_mobile }}</td> -->
            <!-- <td class="text-right"  style="vertical-align: middle; text-align: left;">{{ item.user_email }}</td> -->
            <td
              class="text-right"
              style="vertical-align: middle; text-align: left;"
            >
              <b>Rs. </b>{{ item.total_charge }}
            </td>
            <td
              class="text-right"
              style="vertical-align: middle; text-align: left;"
              v-if="item.payment_type === 3"
            >
              Bank Transfer
            </td>
            <td
              class="text-right"
              style="vertical-align: middle; text-align: left;"
              v-if="item.payment_type === 4"
            >
              Credit Card
            </td>

            <td
              class="text-right action-style"
              style="vertical-align: middle; text-align: left;"
            >
              <button
                type="button"
                class="btn btn-sm merchprof-sbtn"
                @click="handleView(item)"
              >
                View</button
              >&nbsp;
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row text-right">
      <div class="col-sm-12">
        <div class="mt-3">
          <b-pagination
            v-model="currentPage"
            :per-page="pageSize"
            :total-rows="rowcount"
            align="right"
          >
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixinStore from "@/mixins/merchant/APIStore";
export default {
  mixins: [mixinStore],
  data: function() {
    return {
      breadcrumb: [
        {
          text: "Pending Approval",
          href: "",
          active: false,
        },
      ],
      statusArray: [
        {
          key: 1,
          text: "Approve",
          value: true,
        },
        {
          key: 2,
          text: "Reject",
          value: false,
        },
      ],
      approveStatus: true,
      object: {},
      showSubComp: 0,
      pageSize: 10,
      currentPage: 1,
      rowcount: 0,
      searchKey: "",
      searchKeyStatus: "all",
      tableItems: [],
      blockLoading: true,
      paymentFilteroptions: [
        { text: "All", value: "all" },
        { text: "Bank Transfer", value: "banktransfer" },
        { text: "Credit Card", value: "creditcard" },
      ],
      userRole: 1,
    };
  },
  watch: {
    "$store.state.merchant_selectedstore": function() {
      this.handleGetOrderList(this.$store.state.merchant_selectedstore);
    },
    searchKey: function(val) {
      let sType = "orderid";
      this.searchCustomers(val, sType);
    },
    currentPage: function(val) {
      if (val) {
        this.pagination(this.pageSize, val);
      }
    },
    pageSize: function() {
      this.searchCustomers(this.searchKey);
    },
  },
  created() {
    this.handleBreadcrumb();
    this.handleGetOrderList(this.$store.state.merchant_selectedstore);
    this.checkUserRole();
  },
  methods: {
    checkUserRole: function() {
      var merchantData = {};
      if ("merchant_data" in localStorage) {
        merchantData = JSON.parse(localStorage.merchant_data);
        this.userRole = merchantData.role;
      }
    },
    filterOrderPayType: function() {
      let sType = "pay";
      this.searchCustomers(this.searchKeyStatus, sType);
    },
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb,
      });
    },
    handleGetOrderList: async function(store) {
      try {
        this.tableItems = [];
        this.storeListData = [];
        this.blockLoading = true;
        if (store === "-1") {
          this.storeListData = await this.getOrderList();
        } else {
          this.storeListData = await this.getOrderListByStore(store);
        }

        var pendingToApproveRejOrders = [];

        // check & filter out rejected order
        pendingToApproveRejOrders = this.storeListData.filter((obj) => {
          return obj.admin_approve == false && obj.order_status != 0;
        });

        //Set item count for orders
        pendingToApproveRejOrders.forEach((obj) => {
          obj.itemCount = obj.order_lines.length;
        });

        this.storeListData = pendingToApproveRejOrders;

        this.searchCustomers("");
        this.blockLoading = false;
      } catch (error) {
        this.$toast.error("Could not get details!", "Error", {
          position: "topRight",
        });
      }
    },
    searchCustomers: function(keyword, type = "") {
      if (type == "pay") {
        let pagesize = parseInt(this.pageSize);
        if (!keyword || keyword.length === 0) {
          this.tableItems = this.storeListData.slice(0, pagesize);
          this.rowcount = this.storeListData.length;
        } else {
          this.tableItems = this.storeListData
            .filter((obj) => {
              {
                if (keyword == "banktransfer") {
                  return obj.payment_type == 3;
                }

                if (keyword == "creditcard") {
                  return obj.payment_type == 4;
                }

                if (keyword == "all") {
                  return obj;
                }
              }
            })
            .slice(0, pagesize);
          this.rowcount = this.storeListData.length;
        }
      } else if ("orderid") {
        let pagesize = parseInt(this.pageSize);
        if (!keyword || keyword.length === 0) {
          this.tableItems = this.storeListData.slice(0, pagesize);
          this.rowcount = this.storeListData.length;
        } else {
          this.tableItems = this.storeListData
            .filter((obj) => {
              {
                return (
                  obj.order_env_number && obj.order_env_number.includes(keyword)
                );
              }
            })
            .slice(0, pagesize);
          this.rowcount = this.storeListData.length;
        }
      } else {
        let pagesize = parseInt(this.pageSize);
        if (!keyword || keyword.length === 0) {
          this.tableItems = this.storeListData.slice(0, pagesize);
          this.rowcount = this.storeListData.length;
        } else {
          this.tableItems = this.storeListData
            .filter((obj) => {
              {
                return (
                  obj.order_env_number && obj.order_env_number.includes(keyword)
                );
              }
            })
            .slice(0, pagesize);
          this.rowcount = this.storeListData.length;
        }
      }
    },
    handleBack() {
      this.object = {};
      this.showSubComp = 0;
    },
    pagination: function(pagesize, currentPage) {
      let pagesizeint = parseInt(this.pageSize);
      let endsize = pagesize * currentPage;
      let startsize = 0;
      if (currentPage === 1) {
        startsize = 0;
      } else {
        startsize = endsize - pagesize;
      }
      this.tableItems = this.storeListData.slice(startsize, endsize);
    },
    handleStatusChange: async function(id, condition) {
      let aproveObj = {};
      aproveObj._id = id;
      aproveObj.status = condition;
      if (condition === true) {
        try {
          await this.changeStatusOrder(aproveObj);
          this.$swal.fire({
            position: "center",
            type: "success",
            title: "Successfully Approved!",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (error) {
          this.$swal.fire({
            position: "center",
            type: "error",
            title: "Couldn't Approved!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        this.handleReject(aproveObj);
      }
      await this.handleGetOrderList();
    },
    handleReject: async function(obj) {
      try {
        let self = this;
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, reject it!",
        }).then((result) => {
          if (result.value) {
            self.rejectOrderDetail(obj);
            this.$swal("Reject!", "Order has been reject.", "success");
          }
        });
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Couldn't Reject!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    rejectOrderDetail: async function(value) {
      try {
        await this.changeStatusOrder(value);
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Couldn't Reject!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    handleView(obj) {
      localStorage.setItem("pending_order_group_id", obj._id);
      this.$router.push({
        name: "Pending Order Lines",
        params: { id: obj._id },
      });
    },
  },
};
</script>
<style>
.avatar-style {
  width: 100px;
  text-align: center;
}
.img-avatar,
.avatar > img,
.img-circle {
  vertical-align: middle;
}
.action-style {
  width: 265px;
}
.head-table {
  background: white !important;
  text-align: left;
}
.table-border-out {
  border: 1px solid #ddd;
}
.merchaprrejodr .merchprof-sbtn {
  background: #5779ae;
  color: #ffffff;
  border: none;
  font-size: 14px;
}
.merchaprrejodr .merchprof-sbtn:hover {
  color: #000000;
}
</style>
